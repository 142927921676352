<template>
  <div>

  </div>
</template>

<script>

export default {
  name: 'homepage',
  data () {
    return {
      info: {},
      datas: [
        { name: '模版试卷数', value: 421 },
        { name: '教师试卷数', value: 445 },
        { name: '自由选题数', value: 526 },
        { name: '弱点训练数', value: 245 },
        { name: '讲义训练数', value: 246 }
      ],
      username: window.localStorage.getItem('userName')
    }
  },
  components: {
  },
  mounted () {

  },
  methods: {
    getList () {
      instance({
        url: '/api/v1/main/datas',
        method: 'POST'
      }).then(res => {
        this.info = res.data.list
        this.$refs.allSubmitRef.dataList = this.info.count_papers_times


        let datas = JSON.parse(JSON.stringify(this.info))
        delete datas.count_papers_times
        let arr = []
        arr.push({ value: datas['count_template'], name: '模版试卷数' })
        arr.push({ value: datas['count_edu'], name: '教师试卷数' })
        arr.push({ value: datas['count_free'], name: '自由选题数' })
        arr.push({ value: datas['count_checkpoint'], name: '弱点训练数' })
        arr.push({ value: datas['count_lecture'], name: '讲义训练数' })

        this.datas = arr
        this.$refs.bingRef.count_all = this.info.count_all
        this.$refs.bingRef.drawLine()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 30px;
}
div {
  font-size: 16px;
  letter-spacing: 1px;
}

.red {
  color: red;
}
.dots {
  position: relative;
}
.dots::before {
  content: ".";
  position: absolute;
  font-size: 22px;
  top: 6px;
  left: 4px;
}
.info_warp {
  width: 100%;
  height: 267px;
  display: flex;
  .left_tag {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    .tag {
      width: 46%;
      display: flex;
      margin: 2%;
      background: white;
      .number {
        flex: 5;
        margin: 15px;
        div {
          &:nth-child(1) {
            color: red;
            color: rgba(0, 0, 0, 0.85);
            font-weight: bold;
            font-size: 24px;
          }
          &:nth-child(2) {
            color: #999999;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
      .tubiao {
        flex: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  .right_welcome {
    flex: 1;
    display: flex;
    background: white;
    width: 46%;
    margin: 1%;
    .text {
      flex: 4;
      flex-wrap: wrap;
      span {
        display: block;
        &:nth-child(1) {
          font-size: 22px;
          font-weight: bold;
          margin: 3%;
        }
        &:nth-child(2) {
          font-size: 16px;
          margin: 1%;
          margin-left: 3%;
          color: #999999;
          line-height: 26px;
        }
      }
    }
    .pic {
      flex: 1;
      text-align: center;
      margin-top: 20px;
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
  .right_welcome1 {
    flex: 1;
    display: flex;
    margin: 1% 1%;
    .tag {
      width: 50%;
      display: flex;
      background: white;
      margin-right: 2%;
      &:nth-child(2) {
        margin-left: 2%;
      }
      .number {
        flex: 5;
        margin: 15px;
        div {
          &:nth-child(1) {
            color: red;
            color: rgba(0, 0, 0, 0.85);
            font-weight: bold;
            font-size: 24px;
          }
          &:nth-child(2) {
            color: #999999;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
      .tubiao {
        flex: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
.submit_warp {
  margin: 0.5%;
  display: flex;
  .left {
    flex: 6;
  }
  .right {
    flex: 4;
  }
}
.tag {
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  top: 0px;
  right: 0px;
  transition: all 0.2s ease;
  &:hover {
    position: relative;
    top: -2px;
    right: 2px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  }
}

.right_welcome {
  cursor: pointer;
  border-radius: 5px;
  top: 0px;
  right: 0px;
  transition: all 0.5s ease;
  &:hover {
    position: relative;
    top: -2px;
    right: 2px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  }
}
</style>